<template>
  <Card class="hello" insideClass="max-w-max">
    <h1>(404) Page NotFound</h1>
  </Card>
</template>
<script>
import Card from './Card.vue';
export default {
  components: { Card },
};
</script>
